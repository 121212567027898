body {
    margin: 0;
    padding: 0;
    font-family: 'Merriweather', serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.account {
    height: 100vh;
    width: 100%;
    min-height: 100vh;
    display: flex;
    overflow-y: auto;
    background: #eee;
}

.account.account--photo {
    background: #6fb9fb;
}

.account.account--photo .account__card {
    background-color: rgba(0, 10, 16, 0.8);
    color: #fff;
}

.account.account--photo .form__form-group-icon, .account.account--photo .form__form-group-button {
    background: transparent;
}

.account.account--photo .form__form-group-icon, .account.account--photo input:not(:last-child) {
    border-right: none;
}

.account.account--photo .form__form-group-button, .account.account--photo input {
    border-left: none;
}

.account.account--photo .form__form-group-button.active {
    border-color: #eff1f5;
}

.account.account--photo .form__form-group-button.active svg {
    fill: #eee;
}

.account.account--photo input {
    color: #fff;
}

.account.account--photo input:focus {
    border-color: #eff1f5;
}

.account.account--photo p {
    color: #fff;
}

.account.account--photo .account__title {
    color: #fff;
}

.account__wrapper {
    margin: auto;
    padding: 10px;
}

.account__card {
    background-color: #fff;
    margin: 30px auto auto auto;
    padding: 50px 60px;
    width: 500px;
}

.account__profile {
    text-align: center;
}

.account__btns {
    display: flex;
    width: calc(100% + 10px);
    margin: -10px 0 -20px -10px;
}

.account__btns a {
    margin: 10px 0 20px 10px;
    white-space: nowrap;
}

.account__btn {
    width: 100%;
    margin-right: 0;
}

.account__avatar {
    height: 64px;
    width: 64px;
    border-radius: 50%;
    overflow: hidden;
}

.account__name {
    font-size: 11px;
    text-transform: uppercase;
    font-weight: 700;
    line-height: 15px;
    margin: 5px 0 5px 0px;
}

.conv__id {
    font-size: 11px;
    font-weight: 700;
    line-height: 15px;
    margin: 5px 0 5px 0px;
}


.account__sub {
    margin-top: 0;
    margin-bottom: 10px;
    color: #656262;
    font-size: 11px;
    line-height: 15px;
}

.button__desc {
    margin-top: 20px;
    margin-bottom: 0px;
    color: #656262;
    font-size: 11px;
    line-height: 15px;
}

.account__forgot-password {
    position: absolute;
    font-size: 11px;
    line-height: 15px;
    bottom: -18px;
    right: 0;
}

.account__forgot-password a {
    color: #70bbfd;
}

.account__forgot-password a:hover {
    color: #3ea3fc;
    text-decoration: none;
}

.form__form-group--forgot {
    margin-bottom: 40px;
}

.account__or {
    text-align: center;
    margin-top: 35px;
    margin-bottom: 20px;
    position: relative;
}

.account__or:before, .account__or:after {
    content: "";
    height: 1px;
    width: calc(50% - 90px);
    background: #ddd;
    position: absolute;
    top: 10px;
}

.account__or:before {
    left: 0;
}

.account__or:after {
    right: 0;
}

.account__social {
    text-align: center;
}

.account__social-btn {
    display: inline-block;
    height: 38px;
    width: 38px;
    border-radius: 5px;
    padding: 9px;
    margin-right: 10px;
    transition: all 0.3s;
    border: none;
}

.account__social-btn:last-child {
    margin-right: 0;
}

.account__social-btn svg {
    fill: #fff;
    height: 20px;
    width: 20px;
}

.account__social-btn.account__social-btn--facebook {
    background: #4766a4;
}

.account__social-btn.account__social-btn--facebook:hover {
    background: #385080;
}

.account__social-btn.account__social-btn--google {
    background: #c74d4d;
}

.account__social-btn.account__social-btn--google:hover {
    background: #ab3636;
}

.account__head {
    margin-bottom: 30px;
    padding-left: 10px;
    border-left: 4px solid #70bbfd;
}

.account__logo {
    font-weight: 700;
}

.account__logo-accent {
    color: #70bbfd;
}

.account__have-account {
    text-align: center;
    margin-top: 20px;
}

.account__have-account a {
    color: #70bbfd;
    transition: all 0.3s;
}

.account__have-account a:hover {
    color: #3ea3fc;
    text-decoration: none;
}

@media screen and (max-width: 520px) {
    .account__card {
        padding: 35px 30px;
    }
}

@media screen and (max-width: 425px) {
    .account__btns {
        flex-wrap: wrap;
    }
}

.form-control {
    margin: 10px 0px;
}

.header-editor {
    background: #fff;
    outline: none;
    padding: 20px;
    border: 1px solid #b3b3b1;
}

.body-editor {
    outline: none;
    background: #fff;
    height: 100vh;
    padding: 20px;
    border: 1px solid #ccc;
}

.currentusers {
    margin: 20px 0;
    display: flex;
}

.navbar-brand {
    font-size: 16px;
}

.userInfo {
    width: 40px;
    height: 40px;
    display: flex;
    border-radius: 50%;
    margin-right: 10px;
    background: #FFF;
}

.container-fluid {
    padding: 0px;
}

.main-content {
    display: flex;
    flex-wrap: nowrap;
    padding: 15px 50px;
    background: #eee;
}

.document-holder {
    flex: 1 1;
}

.activity-holder {
    width: 500px;
}

.history-holder {
    background-color: #fff;
    margin: 30px auto auto auto;
    padding: 10px 10px;
    width: 500px;
    background: #FFF;
    min-width: 300px;
    height: 500px;
    border: 1px solid #ccc;
}

.history-holder ul {
    padding: 9px;
    list-style: none;
    font-size: 12px;
    color: #4e4e4e;
}

.history-holder li {
	margin-bottom: 10px;
}

.btn-primary {
    margin-top: 5px;
}

.ok-msg {
    margin-top: 10px;
    padding: 5px;
    border: 1px solid cadetblue;
    border-radius: 4px;
    text-align: center;
    background-color: darkseagreen;
    font-size: 14px;
    width: 380px;
}

.error-msg {
    margin-top: 10px;
    padding: 5px;
    border: 1px solid #ab3636;
    border-radius: 4px;
    text-align: center;
    background-color: #c74d4d;
    width: 380px;
}

.ok-text {
    margin-top: 10px;
    color: white;
    font-size: 14px;
}

.error-text {
    margin-top: 10px;
    color: white;
    font-size: 14px;
}

.error-input {
    border: 2px solid red;
}

.error-input:focus {
    outline: none;
    border-color: red;
    box-shadow: 0 0 10px red;
}

.radio-toolbar {
    margin: 10px;
}

.radio-toolbar input[type="radio"] {
    opacity: 0;
    position: fixed;
    width: 0;
}

.radio-toolbar label {
    display: inline-block;
    cursor: pointer;
    padding: 0px 15px;
    line-height: 34px;
    border: 1px solid #999;
    border-radius: 6px;
    -webkit-user-select: none;
            user-select: none;
}

.radio-toolbar label:hover {
    color: #666;
}

.radio-toolbar input[type="radio"]:checked + label {
    background: #ffe0a6;
}

